import * as React from 'react';
import styled from 'styled-components';
// import {useStaticQuery, graphql} from 'gatsby';
// import {GatsbyImage, getImage} from 'gatsby-plugin-image';
// import Gallery from '@browniebroke/gatsby-image-gallery';

import '@fontsource/shadows-into-light';
import '@fontsource/sacramento';
import 'fontsource-open-sans/300.css';
import 'normalize.css';
import Page from '../components/Page';
import Box from '../components/Box';
import Title from '../components/Title';
import ContentWrapper from '../components/ContentWrapper';

// styles
const TitleWrapper = styled(Box).attrs({
  mt: '35px',
})``;

const SubTitle = styled(Box).attrs({
  mt: '40px',
  mb: '25px',
  fontSize: ['20px', null, null, '22px'],
})`
  font-family: 'Open Sans';
  font-weight: bold;
`;
const Content = styled(Box).attrs({
  mb: '30px',
  fontSize: ['20px', null, null, '22px'],
})`
  font-family: 'Open Sans';
`;

// const useGallery = () => {
//   const data = useStaticQuery(graphql`
//     query GalleryImages {
//       images: allS3Object(filter: {Key: {glob: "content/gallery/*"}}) {
//         nodes {
//           Key
//           localFile {
//             childImageSharp {
//               thumb: gatsbyImageData(width: 270, height: 270, placeholder: BLURRED)
//               full: gatsbyImageData(layout: FULL_WIDTH)
//             }
//           }
//         }
//       }
//     }
//   `);
//   return data.images.nodes.map(node => node.localFile.childImageSharp);
// };

const DirectionsPage = () => {
  return (
    <Page>
      <ContentWrapper>
        <TitleWrapper>
          <Title text={'Driving Directions'} />
        </TitleWrapper>
        <Box m={'0 auto'} maxWidth={'1000px'}>
          <SubTitle>From Denver International Airport to Beaver Creek</SubTitle>
          <Content>
            Exit airport on Pena Blvd. Merge onto I-70 west via the exit on the left, stay for approx. 90 miles.
            Continue on I-70 until you reach exit 147 Avon/Beaver Creek. Drive south off the ramp (traveling through the
            roundabout, under highway 70). Continue straight through 4 more roundabouts entering the Beaver Creek
            Welcome Gate. Tell them which property you are checking into. They will provide you with a map and
            directions to the resort.
          </Content>
          <SubTitle>From Eagle Vail Airport to Beaver Creek</SubTitle>
          <Content>
            Exit the airport taking a left on Cooley Mesa Road. Turn right onto Highway 6, eastbound, and follow it
            about 5 miles straight through one roundabout. At the second roundabout take your 3rd right (heading north).
            Take I-70 eastbound toward Avon/Beaver Creek exit #147 for approximately 20 miles. Drive south off the ramp
            (traveling through the roundabout, under highway 70). Continue straight through 4 more roundabouts entering
            the Beaver Creek Welcome Gate. Tell them where you are going and they will provide you with a map and
            directions.
          </Content>
        </Box>
      </ContentWrapper>
    </Page>
  );
};

export default DirectionsPage;
